e4.section = {
    init: function () {
        if ( e4.settings.event.transitionend !== false ) {
            e4.section.event = e4.util.registerEvents( 'stick sticked unstick unsticked', 'section' );
            e4.section.sticky.init();
        }
    },
    // TODO: Fix chrome bug with first sticky section on a page does not fire transition events.
    sticky: {
        init: function () {
            var jSection = jQuery( '.js-e-section.is-sticky' );
            if ( jSection.length > 0 ) {
                jSection.data( 'isTransitioning', false );
                e4.section.sticky.isRAF = false;
                e4.section.sticky.updateAllOffsets();
                e4.section.sticky.bindEvents();
                e4.section.sticky.toggle();
            }
        },
        bindEvents: function () {
            jQuery( window ).on( 'scroll', function () {
                if ( e4.section.sticky.isRAF === false ) {
                    window.requestAnimationFrame( function () {
                        e4.section.sticky.toggle();
                        e4.section.sticky.isRAF = false;
                    } );
                    e4.section.sticky.isRAF = true;
                }
            } );
        },
        updateAllOffsets: function () {
            jQuery( '.js-e-section.is-sticky' ).not( '.is-stuck' ).each( function ( index, domElm ) {
                var jSection = jQuery( domElm ),
                    intOffsetTop;
                jSection.removeClass( 'is-sticky' );
                intOffsetTop = parseInt( jSection.offset().top );
                // console.log( jSection.attr( 'id' ), jSection.data() );
                jSection.data( {
                    'intOffsetStick': intOffsetTop + parseInt( jSection.css( 'padding-top' ) ),
                    'intOffsetUnStick': intOffsetTop
                } );
                // console.log( jSection.attr( 'id' ), jSection.data() );
                jSection.addClass( 'is-sticky' );
            } );
        },
        toggle: function () {
            var intScrollTop = parseInt( jQuery( window ).scrollTop() );
            jQuery( '.js-e-section.is-sticky' ).each( function ( index, domElm ) {
                var jSection = jQuery( domElm );
                //if ( jSection.hasClass( 'bg-warning' ) ) {
                //console.log( jSection.data(), jSection.is( '.is-stuck' ), intScrollTop )
                //}
                if ( jSection.data( 'isTransitioning' ) === false ) {
                    if ( jSection.is( '.is-stuck' ) === false && intScrollTop > jSection.data( 'intOffsetStick' ) ) {
                        // console.log( jSection.attr( 'id' ), 'e4.section.sticky.Stick' );
                        e4.section.sticky.stick( domElm );
                    }
                    else if ( jSection.is( '.is-stuck' ) === true && intScrollTop <= jSection.data( 'intOffsetUnStick' ) ) {
                        // console.log( jSection.attr( 'id' ), 'e4.section.sticky.Unstick' );
                        e4.section.sticky.unstick( domElm );
                    }
                }
            } );
        },
        stick: function ( domElm ) {
            var jSection = jQuery( domElm );
            jSection.data( 'isTransitioning', true );
            jSection.one( e4.settings.event.transitionend, function () {
                jSection.data( 'isTransitioning', false );
                // console.log( jSection.attr( 'id' ), jSection.data( 'isTransitioning' ), 'trans sticked' );
                e4.section.sticky.updateAllOffsets();
                jSection.trigger( e4.section.event.sticked );
            } );
            jSection.addClass( 'is-stuck' );
            jSection.trigger( e4.section.event.stick );
        },
        unstick: function ( domElm ) {
            var jSection = jQuery( domElm );
            jSection.data( 'isTransitioning', true );
            jSection.one( e4.settings.event.transitionend, function () {
                jSection.data( 'isTransitioning', false );
                e4.section.sticky.updateAllOffsets();
                // console.log( jSection.attr( 'id' ), jSection.data( 'isTransitioning' ), 'trans unsticked' );
                jSection.trigger( e4.section.event.unsticked );
            } );
            // console.log( jQuery._data( domElm, 'events' ).transitionend[ 0 ].handler );
            jSection.removeClass( 'is-stuck' );
            jSection.trigger( e4.section.event.unstick );
        }
    }
};

