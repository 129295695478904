e4.scrollReveal = {
    init: function () {
        e4.scrollReveal.conceal( e4.settings.scrollReveal.classConceal );
        e4.scrollReveal.reveal( e4.settings.scrollReveal.classRevealFade );
        e4.scrollReveal.bindEvents();
    },
    bindEvents: function () {
        e4.scrollReveal.isRAF = false;
        jQuery( window ).on( 'scroll', function () {
            if ( e4.scrollReveal.isRAF === false ) {
                window.requestAnimationFrame( function () {
                    e4.scrollReveal.reveal( e4.settings.scrollReveal.classRevealSlide );
                    e4.scrollReveal.isRAF = false;
                } );
                e4.scrollReveal.isRAF = true;
            }
        } );
    },
    conceal: function ( strClass ) {
        jQuery( e4.settings.scrollReveal.selector ).each( function () {
            var jThis = jQuery( this );
            if ( e4.util.isInViewport( jThis ) === false ) {
                jThis.addClass( strClass );
                // console.log( 'conceal', jThis );
            }
            else {
                // TODO: Lav seperat p-txt-animate funktion for undgå denne
                jThis.removeClass( [
                    e4.settings.scrollReveal.selector.substring( 1 ),
                    e4.settings.scrollReveal.classConceal,
                    e4.settings.scrollReveal.classRevealFade,
                    e4.settings.scrollReveal.classRevealSlide
                ].join( ' ' ) );
                jThis.addClass( e4.settings.scrollReveal.classRevealDone );
                // console.log( 'done', jThis );
            }
        } );
    },
    reveal: function ( strClass ) {
        jQuery( e4.settings.scrollReveal.selector ).each( function () {
            var jThis = jQuery( this );
            if ( e4.util.isInViewport( jThis ) === true ) {
                // console.log( 'reveal', this );
                jThis.removeClass( e4.settings.scrollReveal.selector.substring( 1 ) );
                jThis.one( e4.settings.event.transitionend, function () {
                    jThis.removeClass( [
                        e4.settings.scrollReveal.classConceal,
                        e4.settings.scrollReveal.classRevealFade,
                        e4.settings.scrollReveal.classRevealSlide
                    ].join( ' ' ) );
                    jThis.addClass( e4.settings.scrollReveal.classRevealDone );
                } );
                jThis.addClass( strClass );
            }
        } );
    }
};
