( function () {
    'use strict';
    jQuery( window ).on( 'load', function () {
        e4.util.debug( 'Event "load" fired on "window".', e4.settings.debug );
        e4.loading.done();
        e4.require.init();
        e4.util.init();
        e4.nav.local.horizontal.init();
        e4.nav.local.vertical.init();
        e4.scrollReveal.init();
        e4.scrollToAnchor.init();
        e4.breadcrumb.init();
        e4.p.init();
        e4.parallax.init();
        e4.search.init();
        e4.login.init();
        e4.cta.init();
        e4.cookies.init();
        e4.cc.init();
        e4.quickorder.init();
        e4.impersonation.init();
        //e4.dealersearch.init();
        e4.bootstrap.dropdown.init();
        e4.bootstrap.carousel.init();
        e4.bootstrap.modal.init();
        e4.bootstrap.collapse.init();
        e4.jsonpublisher.init();
        e4.section.init();
        e4.news.init();
        e4.nav.util.init();
        e4.animateTxt.init();
        e4.animateNumber.init();
    } );
}() );

