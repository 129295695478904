e4.jsonpublisher = {
    debug: false,
    init: function () {
        var jJsonPublishers = jQuery( '.js-e-jsonpublisher' );
        if ( jJsonPublishers.length > 0 ) {
            e4.jsonpublisher.event = e4.util.registerEvents( 'jsonready load loaded', 'jsonpublisher' );
            e4.require.js( e4.settings.require.handlebars, function () {
                jJsonPublishers.each( function ( intIndex, domNode ) {
                    e4.jsonpublisher.load( domNode );
                } );
            } );
        }
    },
    load: function ( domNode ) {
        var jJsonPublisher = jQuery( domNode ),
            jTarget = e4.jsonpublisher.getTarget( jJsonPublisher ),
            fnTemplate = e4.jsonpublisher.getTemplate( jJsonPublisher ),
            strJsonSrcProp = jJsonPublisher.data( 'jsonSrcProp' ),
            intItemCount = jJsonPublisher.data( 'jsonItemCount' ),
            strItemCountProp = jJsonPublisher.data( 'jsonItemCountProp' ),
            strJsonTemplateProp = jJsonPublisher.data( 'jsonTemplateProp' );
        jJsonPublisher.trigger( e4.jsonpublisher.event.load );
        // Wait for promise to resolve.
        jQuery.when( e4.jsonpublisher.getJson( jJsonPublisher ) ).then( function ( jsonData ) {
            // If successful we process and transform the JSON to HTML before injecting it into the target node.
            jJsonPublisher.trigger( e4.jsonpublisher.event.jsonready, jsonData );
            jsonData = e4.jsonpublisher.getJsonFromProp( jsonData, strJsonSrcProp );
            jsonData = e4.jsonpublisher.getJsonItemCount( jsonData, intItemCount, strItemCountProp );
            jsonData = e4.jsonpublisher.putJsonToProp( jsonData, strJsonTemplateProp );
            jTarget.html( fnTemplate( jsonData ) );
            jJsonPublisher.trigger( e4.jsonpublisher.event.loaded );
        }, function () {
            // Otherwise something is wrong and we throw a warning.
            e4.util.debug( arguments, e4.jsonpublisher.debug );
        } );
    },
    getJson: function ( jJsonPublisher ) {
        var dfdReturnValue = jQuery.Deferred(),
            strJsonSrc = jJsonPublisher.data( 'jsonSrc' ),
            strJsonSrcGetParamsPropertyPath = jJsonPublisher.data( 'jsonSrcGetParams' );
        // If the specified JSON source is a link we will return a jqXHR promise.
        if ( strJsonSrc !== undefined && strJsonSrc.indexOf( '/' ) === 0 ) {
            dfdReturnValue = e4.jsonpublisher.getJsonFromUrl( strJsonSrc, strJsonSrcGetParamsPropertyPath );
        }
        // Else if not invalid, we will assume that it is a property in the e4.data namespace, so we look it up, and resolve the deferred object with the return value.
        else if ( strJsonSrc !== undefined && strJsonSrc !== '' ) {
            dfdReturnValue.resolve( e4.jsonpublisher.getJsonFromInlineScript( strJsonSrc ) );
        }
        // Otherwise something is wrong and we will reject the deferred object.
        else {
            dfdReturnValue.reject( 'Failed to fetch JSON source: "' + strJsonSrc + '" for Handlebars component: ' + jJsonPublisher );
        }
        return dfdReturnValue.promise();
    },
    getJsonFromInlineScript: function ( strJsonSrc ) {
        var jsonReturnValue = {};
        // Look for the specified JSON property in the e4.data namespace and return it.
        if ( strJsonSrc !== undefined && strJsonSrc !== '' ) {
            jsonReturnValue = e4.data.get( strJsonSrc );
        }
        return jsonReturnValue;
    },
    getJsonFromUrl: function ( strJsonSrc, strJsonSrcGetParamsPropertyPath ) {
        var objJsonSrcGetParams = {};
        // If a property path for url get parameters is specified, we use e4.data function to fetch the data object, and pass it to jQuery for encoding and formatting.
        if ( strJsonSrcGetParamsPropertyPath !== undefined && strJsonSrcGetParamsPropertyPath !== '' ) {
            objJsonSrcGetParams = e4.data.get( strJsonSrcGetParamsPropertyPath );
        }
        return jQuery.ajax( {
            url: strJsonSrc,
            data: objJsonSrcGetParams,
            method: 'GET',
            dataType: 'json',
            cache: false
        } );
    }, /* This function selects only the data in the specified property and returns that as a new object. This can be used in combination with putJsonToProp to rename properties in the JSON object or to strip away unnecessary data in the JSON object. */
    getJsonFromProp: function ( jsonData, strJsonProp ) {
        var jsonReturnValue = {};
        // If no JSON property is specified we will return the original JSON object.
        if ( strJsonProp === undefined || strJsonProp === '' ) {
            jsonReturnValue = jsonData;
        }
        // If a JSON property is specified we will look for it in the JSON object, and only return the data from that specific property fx relatedProducts.
        else if ( jsonData[ strJsonProp ] !== undefined ) {
            jsonReturnValue = jsonData[ strJsonProp ];
        }
        // If we do not find it, something is wrong and we will return an empty JSON object.
        else {
            e4.util.debug( 'Property "' + strJsonProp + '" not found in JSON. Returning empty JSON object:', jsonData, e4.jsonpublisher.debug );
        }
        return jsonReturnValue;
    }, /* This function does the opposite of getJsonFromProp and is used for wrapping data in a new object. This is useful when the original does not match the names in a Handlebars template fx when we want to print out relatedProducts with a {{#each products}} statement. */
    putJsonToProp: function ( jsonData, strJsonTemplateProp ) {
        var jsonReturnValue = {};
        // If no JSON property is specified we will return the original JSON object.
        if ( strJsonTemplateProp === undefined || strJsonTemplateProp === '' ) {
            jsonReturnValue = jsonData;
        }
        // If a JSON property is specified wrap the original JSON object in that property, and return a new JSON object.
        else {
            jsonReturnValue[ strJsonTemplateProp ] = jsonData;
        }
        return jsonReturnValue;
    },
    getJsonItemCount: function ( jsonData, intItemCount, strItemCountProp ) {
        var jsonReturnValue = jsonData;
        // Check that we have a valid number for trimming our JSON.
        if ( jQuery.isNumeric( intItemCount ) && intItemCount > 0 ) {
            // If a JSON property is specified and it exists on the JSON object and it contains an array, we trim that property.
            if ( strItemCountProp !== undefined && strItemCountProp !== '' && jsonData.hasOwnProperty( strItemCountProp ) && jQuery.isArray( jsonData[ strItemCountProp ] ) === true ) {
                jsonData[ strItemCountProp ] = jsonData[ strItemCountProp ].slice( 0, intItemCount );
                jsonReturnValue = jsonData;
            }
            // If the JSON object itself is an array, we trim the object.
            else if ( jQuery.isArray( jsonData ) === true ) {
                jsonReturnValue = jsonData.slice( 0, intItemCount );
            }
            // Otherwise we throw a warning and return the original JSON.'
            else {
                e4.util.debug( 'Could not find anything to trim. Returning original JSON.', e4.jsonpublisher.debug );
            }
        }
        return jsonReturnValue;
    },
    getTemplate: function ( jJsonPublisher ) {
        var fnReturnValue = function ( jsonData ) {
                return JSON.stringify( jsonData );
            },
            strTemplateSelector = jJsonPublisher.data( 'template' ),
            strTemplateName = e4.handlebars.getTemplateName( strTemplateSelector );
        // Register template helper dependencies.
        e4.jsonpublisher.registerTemplateHelpers( jJsonPublisher );
        // Check if we already have a compiled template in the e4.handlebars namespace.
        if ( jQuery.isFunction( e4.handlebars.tmpl[ strTemplateName ] ) === true ) {
            fnReturnValue = e4.handlebars.tmpl[ strTemplateName ];
        }
        // If not we use the e4.handlebars namespace to compile and save the template.
        else {
            e4.handlebars.registerTemplates( strTemplateSelector );
            // If compilation succeeded we use the compiled template.
            if ( jQuery.isFunction( e4.handlebars.tmpl[ strTemplateName ] ) === true ) {
                fnReturnValue = e4.handlebars.tmpl[ strTemplateName ];
            }
            // Otherwise we throw a warning and fallback to use JSON.stringify function to print out JSON in target node.'
            else {
                e4.util.debug( 'No template found with selector: "' + strTemplateSelector + '". Returning JSON.stringify function.', e4.jsonpublisher.debug );
            }
        }
        return fnReturnValue;
    },
    registerTemplateHelpers: function ( jJsonPublisher ) {
        var strTemplateHelpers = jJsonPublisher.data( 'template-helpers' );
        if ( strTemplateHelpers !== undefined ) {
            e4.handlebars.registerHelpers( strTemplateHelpers );
        }
    },
    getTarget: function ( jJsonPublisher ) {
        var jReturnValue = jJsonPublisher,
            strTargetSelector = jJsonPublisher.data( 'target' ),
            jTarget = jQuery();
        // Validate that we have a class or id selector.
        if ( strTargetSelector !== undefined && ( strTargetSelector.indexOf( '.' ) === 0 || strTargetSelector.indexOf( '#' ) === 0 ) ) {
            // Target node must be inside component root node.
            jTarget = jJsonPublisher.find( strTargetSelector );
        }
        // Make sure that we have one and only one unique target node.
        if ( jTarget.length === 1 ) {
            jReturnValue = jTarget;
        }
        // Otherwise we fallback to use component root node.
        else {
            e4.util.debug( 'No target node found with selector: "' + strTargetSelector + '". Returning component root node.', e4.jsonpublisher.debug );
        }
        return jReturnValue;
    }
};

