//// https://api.jquery.com/jquery.extend/#jQuery-extend-deep-target-object1-objectN
// ( function () {
//    'use strict';
//    jQuery.extend( true, e4.settings, {
//        debug: {
//            show: true,
//            consoleType: 'trace'
//        }
//    } );
// }() );
//
