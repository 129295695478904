e4.breadcrumb = {
    init: function () {
        var jBreadcrumb = jQuery( '.js-e-breadcrumb' ),
            jBreadcrumbItemAppend = jQuery( '.js-e-breadcrumb-item-append' );
        if ( jBreadcrumb.length === 1 && jBreadcrumbItemAppend.length === 1 ) {
            var jBreadcrumbItemLast = jBreadcrumb.find( '.breadcrumb-item' ).last(),
                jBreadcrumbItemNew = jBreadcrumbItemLast.clone();
            jBreadcrumbItemNew.find( 'a' ).attr( 'href', jBreadcrumbItemAppend.data( 'link' ) );
            jBreadcrumbItemNew.find( 'span' ).text( jBreadcrumbItemAppend.data( 'text' ) );
            jBreadcrumbItemNew.find( 'meta' ).attr( 'content', function( intIndex,  strCurrentValue ){
                return parseInt(strCurrentValue) + 1;
            } );
            jBreadcrumbItemLast.after( jBreadcrumbItemNew );
        }
    }
};

