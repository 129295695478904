var e4 = e4 || {},
        dakarefood = dakarefood || {};
( function () {
    'use strict';
    jQuery( function () {
        dakarefood.init();
    } );
    dakarefood.init = function () {
        dakarefood.wasteToBiogasCalculator.init();
        dakarefood.printThis.init();
        dakarefood.activeCampaign.init();
        dakarefood.gdpr.init();
    };


    dakarefood.gdpr = {
        lang: jQuery( 'html' ).attr( 'lang' ),
        init: function () {
            dakarefood.gdpr.add();
        },
        add: function () {
            jQuery( 'form' ).find( 'label, .form-group, ._error-inner' ).each( function () {
                var html = jQuery( this ).html();
                if ( html.indexOf( '{gdpr1}' ) > -1 ) {
                    html = html.replace( '{gdpr1}', '<a href="' + window.gdprData[ dakarefood.gdpr.lang ].gdpr1.url + '" target="_blank">' + window.gdprData[ dakarefood.gdpr.lang ].gdpr1.link + '</a>' );
                    jQuery( this ).html( html );
                }
            } );
        }
    };    

    dakarefood.activeCampaign = {
        init: function () {
            if ( jQuery( '.js-dakarefood-activecampaign' ).length > 0 ) {
                dakarefood.activeCampaign.styling();
            }
        },
        styling: function () {
            jQuery('.js-dakarefood-activecampaign style').remove();
            jQuery( '.js-dakarefood-activecampaign' ).addClass( 'fade-in' );
        }
    };    
    
    dakarefood.wasteToBiogasCalculator = {
        init: function () {
            var arrRates = jQuery ( '.js-dakarefood-waste-to-biogas-rates');
            dakarefood.wasteToBiogasCalculator.arrRates = arrRates.toArray();
            dakarefood.wasteToBiogasCalculator.bind();
            dakarefood.wasteToBiogasCalculator.replaceTextWithCalculatedValue();
            //dakarefood.wasteToBiogasCalculator.addMeasurementSuffixToInput();
        },
        bind: function ( ) {
            var jInput = jQuery ( '.js-dakarefood-waste-to-biogas-calculate-amount' ).toArray();
            jQuery ( jInput ).on('input', function( event ){
                dakarefood.wasteToBiogasCalculator.getValueFromInput( event.target );
                this.style.width = 1 + this.value.length + "ch";
            });
            dakarefood.wasteToBiogasCalculator.triggerFocusWhenIsInViewportTrue( jInput );
        },
        replaceTextWithCalculatedValue: function () {
           jQuery('.js-dakarefood-waste-to-biogas-replacevalue').each(function () { 
                var calculateThisValue = jQuery( this ),
                    calculatedValue = calculateThisValue.text().replace('[VALUE]', '<span class="h2 js-dakarefood-waste-to-biogas-calculate-this-value">0</span>');
                
                calculateThisValue.html( calculatedValue ); 
            });
        },
        getValueFromInput: function ( domInput ) {
            var intAmountInKilograms = parseFloat( jQuery( domInput ).val() );
            dakarefood.wasteToBiogasCalculator.checkIfValueIsANumberThenHideOrShowContent( intAmountInKilograms );
        },
        checkIfValueIsANumberThenHideOrShowContent: function ( intAmountInKilograms ) {
            if ( isNaN( intAmountInKilograms ) ){
                intAmountInKilograms = 0;
                jQuery( '.js-dakarefood-waste-to-biogas-value-container' ).addClass('d-none');
                jQuery( '.js-print-calculation' ).addClass('d-none');
            } else {
                jQuery( '.js-dakarefood-waste-to-biogas-value-container' ).removeClass('d-none');
                jQuery( '.js-print-calculation' ).removeClass('d-none');
                dakarefood.wasteToBiogasCalculator.calculateValueFromInput( intAmountInKilograms );
            }
        },
        calculateValueFromInput: function ( intAmountInKilograms ) {
            var intCalculatedAmount = 0,
                intRate = 0;
             dakarefood.wasteToBiogasCalculator.arrRates.forEach( function( domRate, index ) {
                intRate = dakarefood.wasteToBiogasCalculator.arrRates[ index ].value;
                intCalculatedAmount = intAmountInKilograms * intRate;
                var jCalculatedValueInput = jQuery( domRate ).closest('.js-dakarefood-waste-to-biogas-calculator').find('.js-dakarefood-waste-to-biogas-calculate-this-value');
                jCalculatedValueInput.text(intCalculatedAmount.toFixed(2)); 
            });
        },
        triggerFocusWhenIsInViewportTrue: function ( jInput ) {
            setTimeout(function (){
                e4.util.observer.bind( jInput, function (objEntry, objObserver){
                    if (objEntry.isIntersecting === true){
                        jQuery(objEntry.target).trigger('focus');
                    }
                });
            },500)
        },
        addMeasurementSuffixToInput: function ( ) {
            jQuery ( '.js-dakarefood-waste-to-biogas-calculate-amount' ).on('click keyup', function( event ){
                var intAmountInKilograms = jQuery(this).val();
                var measurementSuffix = intAmountInKilograms.substring(0, intAmountInKilograms.length - 3) + ' kg';
                var cursorPosition = measurementSuffix.length - 3;
                
                if ( !isNaN( intAmountInKilograms ) ){
                    jQuery(this).val( measurementSuffix );
                    jQuery(this)[0].selectionStart = jQuery(this)[0].selectionEnd = cursorPosition;
                }
            });
        }
    };
    dakarefood.printThis = {
        init: function () {
            e4.require.js( e4.settings.require.printThis, function () {
                dakarefood.printThis.bindEvent();
            });
        },
        bindEvent: function () {
            jQuery( '.js-print-calculation' ).on( 'click',  function ( event ) {
                jQuery(event.target).closest('.dakarefood-waste-to-biogas-container').printThis();
            }); 
        }
    };
}() );

