e4.cookies = {
    init: function () {
        if ( jQuery( '.js-e-cookie' ).length === 1 ) {
            jQuery( '.js-e-cookie' ).removeAttr( 'hidden' );
            e4.cookies.bindEvents();
        }
    },
    bindEvents: function () {
        var jCookie = jQuery( '.js-e-cookie' );
        jCookie.find( '.js-e-cookie-accept-btn' ).on( 'click', function ( event ) {
            event.preventDefault();
            jQuery.get( e4.settings.cookieAcceptLink );
            jCookie.addClass( 'is-accepted' );
        } );

    }
};

