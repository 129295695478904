e4.impersonation = {
    init: function () {
        if ( jQuery( '.js-e-extranet-impersonatable-users' ).length === 1 ) {
            e4.require.js( e4.settings.require.handlebars, function () {
                e4.handlebars.registerTemplates( '#js-e-handlebars-tmpl-extranet-impersonatable-users' );
                e4.impersonation.bindEvents();
                e4.impersonation.load();
            } );
        }
    },
    bindEvents: function () {
        jQuery( '.js-e-extranet-impersonatable-users-search-form' ).on( 'submit', function ( event ) {
            event.preventDefault();
            e4.impersonation.seacrh( jQuery( event.target ).find( '.js-e-extranet-impersonatable-users-search-input' ).get( 0 ) );
        } );
        jQuery( '.js-e-extranet-impersonatable-users-search-input' ).on( 'input', e4.util.debounce( function ( event ) {
            e4.impersonation.toggleInputGroupState( event.target );
            jQuery( event.target ).closest( '.js-e-extranet-impersonatable-users-search-form' ).triggerHandler( 'submit' );
        }, e4.settings.debounceDelay.input ) );
        jQuery( '.js-e-extranet-impersonatable-users-search-clear' ).on( 'click', function ( event ) {
            e4.impersonation.clearInput( event.target );
        } );
    },
    toggleInputGroupState: function ( domElm ) {
        var jSearchInputGroup = jQuery( domElm ).closest( '.js-e-extranet-impersonatable-users-search-input-group' );
        if ( domElm.value === '' ) {
            jSearchInputGroup.addClass( 'is-empty' );
        }
        else {
            jSearchInputGroup.removeClass( 'is-empty' );
        }
    },
    clearInput: function ( domElm ) {
        jQuery( domElm ).closest( '.js-e-extranet-impersonatable-users-search-input-group' ).find( '.js-e-extranet-impersonatable-users-search-input' ).val( '' ).trigger( 'focus' ).triggerHandler( 'input' );
        sessionStorage.removeItem( 'e4.impersonation.searchQuery' );
    }, //    jqXHR: null,
    ajax: function ( objAjaxSettings, fnCallback ) {
        if ( this.jqXHR !== undefined && this.jqXHR.readyState !== 4 ) {
            this.jqXHR.abort();
        }
        this.jqXHR = jQuery.ajax( jQuery.extend( {
            url: '/system/data/users-impersonatable',
            method: 'GET',
            dataType: 'json',
            cache: true
        }, objAjaxSettings ) );
        this.jqXHR.done( function ( data ) {
            if ( typeof fnCallback === 'function' ) {
                fnCallback( data );
            }
        } );
    },
    load: function () {
        var jInput = jQuery( '.js-e-extranet-impersonatable-users-search-input' ),
            strQuery = sessionStorage.getItem( 'e4.impersonation.searchQuery' );
        if ( strQuery !== null ) {
            jInput.val( strQuery );
            e4.impersonation.toggleInputGroupState( jInput.get( 0 ) );
        }
        e4.impersonation.ajax( {
            data: jInput.closest( 'form' ).serialize()
        }, function ( data ) {
            e4.impersonation.update( data );
            e4.loading.resolveWait( '.js-e-extranet-impersonatable-users' );
        } );
    },
    seacrh: function ( domElm ) {
        e4.impersonation.ajax( {
            data: jQuery( domElm.form ).serialize()
        }, function ( data ) {
            e4.impersonation.update( data );
        } );
    },
    update: function ( data ) {
        sessionStorage.setItem( 'e4.impersonation.searchQuery', data.q );
        jQuery( '.js-e-extranet-impersonatable-users' ).html( e4.handlebars.tmpl.extranetImpersonatableUsers( data ) );
        e4.loading.resolveWait( '.js-e-extranet-impersonatable-users' );
    }
};

