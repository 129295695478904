e4.animateNumber = {
    init: function () {
        var jNumber = jQuery( '.js-e-animate-number' );
        if ( jNumber.length > 0 ) {
            e4.animateNumber.fixElementWidths();
            e4.animateNumber.animate();
            e4.animateNumber.bindEvents();
        }
    },
    fixElementWidths: function () {
        jQuery( '.js-e-animate-number' ).html( function ( index, oldHtml ) {
            return jQuery( '<span></span>' ).text( oldHtml ).addClass( 'e-animate-number' ).width( this.offsetWidth );
        } );
    },
    bindEvents: function () {
        e4.animateNumber.isRAF = false;
        jQuery( window ).on( 'scroll', function () {
            if ( e4.animateNumber.isRAF === false ) {
                window.requestAnimationFrame( function () {
                    e4.animateNumber.animate();
                    e4.animateNumber.isRAF = false;
                } );
                e4.animateNumber.isRAF = true;
            }
        } );
    },
    animate: function () {
        jQuery( '.js-e-animate-number' ).each( function () {
            var jThis = jQuery( this );
            if ( e4.util.isInViewport( jThis ) === true ) {
                jThis.removeClass( 'js-e-animate-number' );
                var jThisNumber      = jThis.find( '.e-animate-number' ),
                    intAnimateToward = e4.util.formatStringToJavascriptNumber( jThis.text() ),
                    intDuration      = e4.settings.animation.speed;
                if ( isNaN( intAnimateToward ) === false ) {
                    if ( intAnimateToward > 10000 ) {
                        intDuration = intDuration * 15;
                    }
                    else if ( intAnimateToward > 5000 ) {
                        intDuration = intDuration * 12.5;
                    }
                    else if ( intAnimateToward > 1000 ) {
                        intDuration = intDuration * 11.125;
                    }
                    else if ( intAnimateToward > 250 ) {
                        intDuration = intDuration * 10;
                    }
                    else if ( intAnimateToward > 100 ) {
                        intDuration = intDuration * 8.75;
                    }
                    else if ( intAnimateToward > 50 ) {
                        intDuration = intDuration * 7.5;
                    }
                    else if ( intAnimateToward > 10 ) {
                        intDuration = intDuration * 6.25;
                    }
                    else {
                        intDuration = intDuration * 5;
                    }
                    // console.log( intAnimateToward, intDuration );
                    jThis.prop( 'counter', 0 ).animate( {
                        counter: intAnimateToward
                    }, {
                        duration: intDuration,
                        easing: 'linear',
                        step: function ( intNumber ) {
                            var strNumber = e4.util.formatJavascriptNumberToLocaleString( Math.ceil( intNumber ) );
                            strNumber     = strNumber.substring( 0, strNumber.indexOf( e4.settings.separatorDecimal ) );
                            jThisNumber.text( strNumber );
                        }
                    } );
                }
            }
        } );
    }
};

