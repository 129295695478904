e4.bootstrap.modal = {
    init: function () {
        e4.bootstrap.modal.bindEvents();
    },
    bindEvents: function () {
        // TODO: Laves om så der i stedet tages højde for om modal'en ligger i et element med Translate og will-change.
        jQuery( '.modal' ).on( 'show.bs.modal', function () {
            jQuery( e4.settings.scrollReveal.selector ).addClass( e4.settings.scrollReveal.classReset );
        } ).on( 'shown.bs.modal', function ( event ) {
            jQuery( event.target ).trigger( 'focus' );
        } ).on( 'hidden.bs.modal', function () {
            jQuery( e4.settings.scrollReveal.selector ).removeClass( e4.settings.scrollReveal.classReset );
        } );
    }
};

