e4.bootstrap.carousel = {
    init: function () {
        e4.bootstrap.carousel.bindEvents();
    },
    bindEvents: function () {
        jQuery( document ).on( 'keydown', function ( event ) {
            jQuery( '.carousel' ).each( function ( intIndex, domNode ) {
                var jCarousel = jQuery( domNode );
                if ( e4.util.isInViewport( jCarousel ) === true ) {
                    if ( /input|textarea/i.test( event.target.tagName ) ) {
                        return;
                    }
                    //console.log( event.which );
                    switch ( event.which ) {
                        case 37:
                            //event.preventDefault();
                            jCarousel.carousel( 'prev' );
                            break;
                        case 39:
                            //event.preventDefault();
                            jCarousel.carousel( 'next' );
                            break;
                        default:
                            return;
                    }
                }
            } );
        } );
        jQuery( '.carousel' ).on( 'mouseenter', '.carousel-indicators li', function () {
            jQuery( this ).trigger( 'click' );
        } ).on( 'swipeleft', function ( event ) {
            jQuery( event.target ).carousel( 'next' );
        } ).on( 'swiperight', function ( event ) {
            jQuery( event.target ).carousel( 'prev' );
        } );
    }
};

